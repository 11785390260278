.pageContainer {
  min-height: 100vh;
  background-color: #f9fafb;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Action Card Styling */
.actionCard {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.actionCardLeft {
  display: flex;
  align-items: center;
}

.backLink {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #4b5563;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s;
}

.backLink:hover {
  color: #1f2937;
}

.backLink i {
  font-size: 1.25rem;
}

.downloadButton {
  background-color: #6366f1 !important;
  border: none !important;
  padding: 0.75rem 1.5rem !important;
  font-weight: 500 !important;
  border-radius: 4px !important;
  box-shadow: 0 4px 6px -1px rgba(99, 102, 241, 0.4),
    0 2px 4px -1px rgba(99, 102, 241, 0.2) !important;
  transition: all 0.2s ease !important;
}

.downloadButton:hover {
  background-color: #4f46e5 !important;
  transform: translateY(-1px);
  box-shadow: 0 6px 8px -1px rgba(99, 102, 241, 0.4),
    0 3px 6px -1px rgba(99, 102, 241, 0.2) !important;
}

.downloadButton:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px -1px rgba(99, 102, 241, 0.4) !important;
}

/* Invoice Container */
.invoiceContainer {
  background-color: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  width: 210mm;
  min-height: 297mm;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.invoiceContent {
  background-color: white;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Status Banner */
.statusBanner {
  position: absolute;
  top: 25px;
  left: -50px;
  color: white;
  padding: 8px 60px;
  transform: rotate(-45deg);
  z-index: 10;
  font-weight: 600;
  font-size: 0.875rem;
  text-transform: uppercase;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
    inset 0 2px 4px rgba(255, 255, 255, 0.1);
  width: 200px;
  text-align: center;
  letter-spacing: 0.05em;
  background-color: #2196f3;
}

.statusBanner[data-status="pending"] {
  background-color: #f59e0b;
}

/* Invoice Header */
.invoiceHeader {
  background-color: #2196f3;
  padding: 1.5rem 2rem;
  color: white;
}

.invoiceHeaderContent {
  display: flex;
  justify-content: flex-end;
}

.headerDetails {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.detailRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3rem;
}

.label {
  color: white;
  font-size: 0.875rem;
  min-width: 100px;
}

.value {
  color: #1f2937;
  font-size: 0.875rem;
}

/* Invoice Body */
.invoiceBody {
  padding: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.addressSection {
  margin-bottom: 2rem;
}

.addressLayout {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  align-items: start;
}

.sectionTitle {
  color: #6b7280;
  font-size: 0.75rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.addressContent {
  font-size: 0.875rem;
  line-height: 1.4;
  color: #111827;
}

.totalSection {
  text-align: right;
}

.totalAmount {
  color: #2196f3;
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 0.25rem;
}

/* Items Grid */
.itemsGrid {
  width: 100%;
  margin-top: 2rem;
  border-top: 2px solid #2196f3;
  padding-top: 1rem;
  flex: 1;
}

.gridHeader {
  display: grid;
  grid-template-columns: 1fr 150px 100px 150px;
  gap: 1rem;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
}

.gridRow {
  display: grid;
  grid-template-columns: 1fr 150px 100px 150px;
  gap: 1rem;
  padding: 0.5rem 0;
  align-items: center;
}

.columnHeader {
  color: #2196f3;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
}

.columnHeader.right {
  text-align: right;
}

.value.right {
  text-align: right;
}

/* Calculations */
.calculations {
  margin-top: 2rem;
  padding-right: 0;
}

.calculationsGrid {
  display: grid;
  grid-template-columns: 1fr 150px;
  gap: 0.5rem;
  width: 100%;
  max-width: 400px;
  margin-left: auto;
}

.calculationsRow {
  display: contents;
}

.calcLabel {
  color: #2196f3;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: right;
  text-transform: uppercase;
}

.calcValue {
  color: #1f2937;
  font-size: 0.875rem;
  text-align: right;
}

.totalRow {
  display: grid;
  grid-template-columns: 1fr 150px;
  gap: 0.5rem;
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid #e5e7eb;
}

.totalLabel {
  color: #2196f3;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: right;
  text-transform: uppercase;
}

.totalValue {
  color: #1f2937;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: right;
}

/* Footer */
.footer {
  margin-top: auto;
  padding: 2rem;
  background-color: #f3f4f6;
  border-top: 1px solid #e5e7eb;
}

.bankDetails {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.detailsTitle {
  color: #1f2937;
  font-size: 0.875rem;
  font-weight: 500;
}

.detailsText {
  color: #4b5563;
  font-size: 0.875rem;
}

/* Loading State */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #6b7280;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .pageContainer {
    padding: 1rem;
  }

  .actionCard {
    flex-direction: column;
    gap: 1rem;
  }

  .downloadButton {
    width: 100%;
  }

  .headerDetails {
    width: 100%;
  }

  .detailRow {
    gap: 1rem;
  }

  .addressLayout {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .totalSection {
    text-align: left;
  }

  .gridHeader,
  .gridRow {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  .columnHeader.right,
  .value.right {
    text-align: left;
  }

  .calculationsGrid,
  .totalRow {
    max-width: 100%;
  }
}

/* Print styles */
@media print {
  .actionCard {
    display: none;
  }

  .pageContainer {
    padding: 0;
    background: white;
  }

  .invoiceContainer {
    box-shadow: none;
  }

  .invoiceHeader {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .footer {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    background-color: #f3f4f6 !important;
  }

  @page {
    margin: 0.5cm;
    size: A4;
  }
}