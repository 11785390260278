/* invoice.module.css */
.pageContainer {
    min-height: 100vh;
    background-color: #f9fafb;
    padding: 2rem;
  }
  
  .invoiceContainer {
    max-width: 56rem;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
                0 2px 4px -1px rgba(0, 0, 0, 0.06); }
  
  .actionButtons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .invoiceContent {
    background-color: white;
  }
  
  .invoiceHeader {
    height: 10rem;
    background-color: #0ea5e9;
    padding: 2rem;
    color: white;
  }
  
  .invoiceHeaderContent {
    display: flex;
    justify-content: space-between;
  }
  
  .invoiceTitle {
    font-size: 2.25rem;
    font-weight: 600;
    color: #e2e8f0;
    align-self: center;
    text-transform: uppercase;
  }
  
  .invoiceInfo {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    color: #f0f9ff;
  }
  
  .infoLabels {
    text-align: right;
    list-style: none;
  }
  
  .infoValues {
    text-align: left;
    list-style: none;
  }
  
  .detailsSection {
    display: flex;
    flex-direction: row;
    padding: 2rem;
  }
  
  .detailsLeft {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .detailsRight {
    width: 33.333333%;
  }
  
  .addressBlock {
    margin-right: 2rem;
  }
  
  .label {
    color: #64748b;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .value {
    color: #0f172a;
    text-transform: capitalize;
    font-family: monospace;
    font-weight: 600;
    margin-bottom: 0.25rem;
  }
  
  .totalSection {
    display: flex;
    justify-content: flex-end;
  }
  
  .totalColumn {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .totalLabel {
    color: #64748b;
    text-transform: uppercase;
    font-size: 1.25rem;
    text-align: end;
  }
  
  .totalAmount {
    color: #0284c7;
    font-size: 1.5rem;
    text-align: end;
    font-weight: 700;
  }
  
  .divider {
    height: 0.25rem;
    background-color: #0ea5e9;
    margin: 0 2rem;
  }
  
  .invoiceBody {
    padding: 0 2rem;
    padding-top: 0.5rem;
  }
  
  .itemsGrid {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
  }
  
  .descriptionColumn {
    width: 66.666667%;
  }
  
  .unitPriceColumn {
    width: 7rem;
    white-space: nowrap;
  }
  
  .qtyColumn {
    width: 4rem;
    text-align: center;
  }
  
  .amountColumn {
    width: 6rem;
  }
  
  .columnHeader {
    color: #0284c7;
    font-size: 1.125rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }
  
  .textEnd {
    text-align: end;
  }
  
  .calculations {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
    padding: 0 1.25rem;
  }
  
  .calculationsGrid {
    display: flex;
    gap: 2rem;
  }
  
  .calcLabels {
    text-align: right;
    margin-right: 1rem;
  }
  
  .calcValues {
    text-align: right;
  }
  
  .totalRow {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    padding: 1rem 3.25rem;
    margin-top: 1rem;
  }
  
  .footer {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-top: 1px solid #e5e7eb;
    background-color: #e2e8f0;
    margin-top: 32.6rem;
  }
  
  
  
  .termsLabel {
    color: #64748b;
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .bankDetails {
    padding-top: 1rem;
  }
  
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  /* Prime React Button Overrides */
  :global(.p-button) {
    margin: 0.25rem;
  }
  
  :global(.p-button-outlined) {
    border-color: #0ea5e9;
    color: #0ea5e9;
  }
  
  :global(.p-button-outlined:hover) {
    background-color: #0ea5e9;
    color: white;
  }
  
  /* Print styles */
  @media print {
    .actionButtons {
      display: none;
    }
  
    .pageContainer {
      padding: 0;
      background-color: white;
    }
  
    .invoiceContainer {
      box-shadow: none;
      max-width: none;
      margin: 0;
    }
  
    .invoiceHeader {
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
      background-color: #0ea5e9 !important;
    }
  
    .divider {
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
      background-color: #0ea5e9 !important;
    }
  
    .footer {
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
      background-color: #e2e8f0 !important;
    }
  
    .invoiceTitle {
      color: #e2e8f0 !important;
    }
  
    .invoiceInfo {
      color: #f0f9ff !important;
    }
  
    .columnHeader {
      color: #0284c7 !important;
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
    }
  
    .totalAmount {
      color: #0284c7 !important;
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
    }
  
    @page {
      margin: 0;
      size: A4;
    }
  
    body {
      margin: 0.2cm;
    }
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .pageContainer {
      padding: 1rem;
    }
  
    .invoiceHeader {
      height: auto;
      padding: 1rem;
    }
  
    .invoiceHeaderContent {
      flex-direction: column;
      gap: 1rem;
    }
  
    .invoiceTitle {
      font-size: 1.75rem;
      text-align: center;
    }
  
    .detailsSection {
      flex-direction: column;
      gap: 2rem;
    }
  
    .detailsLeft {
      width: 100%;
    }
  
    .detailsRight {
      width: 100%;
    }
  
    .itemsGrid {
      flex-direction: column;
      gap: 1rem;
    }
  
    .descriptionColumn,
    .unitPriceColumn,
    .qtyColumn,
    .amountColumn {
      width: 100%;
      text-align: left;
    }
  
    .calculations {
      padding: 0;
    }
  
    .totalRow {
      padding: 1rem;
    }
  }